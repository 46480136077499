import {EventEmitter, Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettings, defaults } from '../config';
import {Hotel} from "../pages/masters/classes/hotel";
import {ConfirmDialogComponent} from "../common-components/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import * as _moment from 'moment';
import { Moment } from 'moment';
import {MenuService} from "./menu.service";

const moment = _moment;

@Injectable({
  providedIn: 'root',
})
export class CoreService {

  mainSelectedHotel: Hotel = null;
  mainHotelChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  hotelMasterDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  mainMonthYear: Moment = this.getMainMonthYear();

  paginationOptionList: number[] = [10, 25, 50, 100];
  mainFullLoader = new BehaviorSubject<any>({ show: false, loadingMessage: 'Please wait...' });

  constructor(public dialog: MatDialog,
              private router: Router,
              private menuService: MenuService) {
  }

  get notify(): Observable<Record<string, any>> {
    return this.notify$.asObservable();
  }

  private notify$ = new BehaviorSubject<Record<string, any>>({});

  getOptions() {
    return this.options;
  }

  setOptions(options: AppSettings) {
    this.options = Object.assign(defaults, options);
    this.notify$.next(this.options);
  }

  private options = defaults;

  getLanguage() {
    return this.options.language;
  }

  setLanguage(lang: string) {
    this.options.language = lang;
    this.notify$.next({ lang });
  }

  logoutClick() {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '290px',
      data: {
        title: 'Confirm',
        message: 'Are you sure you want to logout?'
      }
    });

    confirmDialogRef.afterClosed().subscribe(result => {
      if (result?.event) {
        this.router.navigate(['/authentication/login']);
      }
    });
  }

  setMainMonthYear(date: Moment) {
    this.mainMonthYear = date;
    localStorage.setItem('mainMonthYear', this.mainMonthYear.toString());
  }

  getMainMonthYear(): Moment {
    const dt = localStorage.getItem('mainMonthYear');
    return dt ? moment(dt) : null;
  }

  franchiseHotel: String = ".";
  gotoDashboard() {
    const selectedHotelObj = localStorage.getItem('mainHotel');
    if (selectedHotelObj) {
      const parsedHotelObj = JSON.parse(selectedHotelObj);
      this.franchiseHotel = ": "+parsedHotelObj.franchiseHotel;
    }
    this.mainFullLoader.next({ show: true, loadingMessage: 'Fetching the details of your selected hotel'+this.franchiseHotel });
    this.menuService.fillMenuAwait().then();
    setTimeout(() => {
      this.router.navigate(['/dashboards/dashboard1']);
      this.mainFullLoader.next({ show: false })
    }, 2000);
  }
}
