import { Component } from '@angular/core';
import {CoreService} from "../../services/core.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'full-main-loader',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinner
  ],
  templateUrl: './full-main-loader.component.html',
  styleUrl: './full-main-loader.component.scss'
})
export class FullMainLoaderComponent {
  isLoading: boolean;
  loadingMessage: string = 'Please wait...';
  constructor(private coreService: CoreService) {
    this.coreService.mainFullLoader.subscribe((v) => {
      this.isLoading = v.show;
      this.loadingMessage = v.loadingMessage;
      //this.loadingMessage = this.loadingMessage;
    });
  }
}
