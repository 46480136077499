import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../classes/user";
import {NavItem} from "../layouts/full/vertical/sidebar/nav-item/nav-item";
import {MasterProvider} from "../providers/master.provider";
import {MenuService} from "./menu.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private dataUpdatedSource = new BehaviorSubject<boolean>(false);
  dataUpdated$ = this.dataUpdatedSource.asObservable();

  notifyDataUpdated() {
      this.dataUpdatedSource.next(true);
  }


  user: BehaviorSubject<User> = new BehaviorSubject<User>(new User(null));
  user$: Observable<User> = this.user.asObservable();

  userTimerInterval = null;
  constructor() {
  }

  setUser(u: User): void {
    this.user.next(u);
  }

  getUser(): User {
    return this.user.getValue();
  }

  get getUserFirstName(): string {
    return this.user?.getValue()?.firstName;
  }

  get getFullUserName(): string {
    return this.user?.getValue()?.firstName + ' ' + this.user?.getValue()?.lastName;
  }

  get getUserEmail(): string {
    return this.user?.getValue()?.email;
  }

  get getUserId(): number {
    return this.user?.getValue()?.id;
  }

  get getRoleType(): string {
    return this.user?.getValue()?.roleType;
  }

  async isUserAvailable(): Promise<number> {
    let hasUser: number = 0;
    if(!this.user?.getValue()?.id){
      await this.fillUserAwait().then(value => {
        if(value) {
          hasUser = value;
        }
      });
    }else{
      hasUser = this.user.getValue().id;
    }
    return hasUser;
  }

  fillUserAwait(): Promise<number> {
    return new Promise((resolve) => {
      this.userTimerInterval = setInterval(() => {
        if (this.user?.getValue()?.id) {
          clearInterval(this.userTimerInterval);
          resolve(this.user.getValue().id);
        }
      }, 500);
    })
  }

}
