import {Injectable} from "@angular/core";
import {NavItem} from "../layouts/full/vertical/sidebar/nav-item/nav-item";
import {MasterProvider} from "../providers/master.provider";
import {UserService} from "./user.service";
import {User} from "../classes/user";
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  isApiCalling: boolean = false;
  timerInterval = null;
  navItems: NavItem[] = [];
  isMenuLoading: boolean = false;
  subActivateRoute: Subscription = Subscription.EMPTY;

  activatedMenu: NavItem = null;

  constructor(private masterProvider: MasterProvider,
              private userService: UserService,
              private router: Router) {
    this.subActivateRoute = router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //console.log(event.url);
        this.getActivatedMenuItem(event.url);
      }
    });
  }

  unsubscribeActivateRoute() {
    if (this.subActivateRoute) {
      this.subActivateRoute.unsubscribe();
    }
  }

  fillMenu(userId?: number) {
    if(!this.isApiCalling) {
      this.isMenuLoading = true;
      this.isApiCalling = true;
      this.masterProvider.getUserWiseMenu(this.userService.getUserId || userId).subscribe(resp => {
        if (resp.statusCode === 1) {
          this.wrapperNavItem(resp.data);
        }
        this.isMenuLoading = false;
        this.isApiCalling = false;
      }, error => {
        this.isMenuLoading = false;
        this.isApiCalling = false;
      });
    }
  }

  fillMenuAwait(): Promise<boolean> {
    return new Promise((resolve) => {

      if(this.isApiCalling) {
        this.timerInterval = setInterval(() => {
          if (!this.isApiCalling) {
            clearInterval(this.timerInterval);
            resolve(true);
          }
        }, 500);
      } else {
        const userObj = new User(JSON.parse(localStorage.getItem('WD-TOKEN')));
        if (userObj.id) {
          this.isMenuLoading = true;
          this.masterProvider.getUserWiseMenu(userObj.id).subscribe(resp => {
            if (resp.statusCode === 1) {
              this.wrapperNavItem(resp.data);

              setTimeout(() => {
                this.isMenuLoading = false;
                resolve(true);
              }, 500);
            } else {
              this.isMenuLoading = false;
              resolve(false);
            }
          }, error => {
            this.isMenuLoading = false;
            resolve(false);
          });
        } else {
          this.isMenuLoading = false;
          resolve(false);
        }
      }
    });
  }

  wrapperNavItem(menuList: any[] = []) {
    this.navItems = [];
    if (menuList?.length) {
      for (let i = 0; i < menuList.length; i++) {
        const menuItem = menuList[i];
        let singleNavItems: NavItem = this.wrapperMenuItem(menuItem, true);
        menuItem?.childlst?.forEach(subMenuItem => {
          singleNavItems.children.push(this.wrapperMenuItem(subMenuItem));
        });
        this.navItems.push(singleNavItems);
      }
    }
  }

  wrapperMenuItem(obj: any, isParent?: boolean): NavItem {
    const newObj = {
      menuId: obj.menuId,
      parentMenuId: obj.parentMenuId,
      displayName: obj.menuName,
      iconName: obj.icon,
      bgcolor: isParent ? 'primary' : 'tranparent',
      route: obj.menuUrl,
      isAdd: obj.isAdd,
      isDelete: obj.isDelete,
      isDownload: obj.isDownload,
      isEdit: obj.isEdit,
      isView: obj.isView,
      isAttachments: obj.isAttachments,
      isProofs: obj.isProofs,
      isConversation: obj.isConversation,
      isBulkUpload: obj.isBulkUpload,
      children: []
    }
    return newObj;
  }

  async getLeftAvailableMenus(): Promise<boolean> {
    let hasMenus: boolean = false;
    if(!this.navItems?.length){
      await this.fillMenuAwait().then(value => {
        if(value) {
          hasMenus = value;
        }
      });
    }else{
      hasMenus = true;
    }
    return hasMenus;
  }

  getActivatedMenuItem(routeUrl: string) {
    this.activatedMenu = null;
    for (const item of this.navItems) {
      if (item.route === routeUrl) {
        this.activatedMenu = item;
        //console.log(this.activatedMenu);
      }

      if (!this.activatedMenu && item?.children?.length) {
        this.activatedMenu = item.children.find(i => i.route === routeUrl) || null;
      }
    }
  }

  get hasViewAccess(): boolean {
    return !!this.activatedMenu?.isView;
  }

  get hasEditAccess(): boolean {
    return !!this.activatedMenu?.isEdit;
  }

  get hasAddAccess(): boolean {
    return !!this.activatedMenu?.isAdd;
  }

  get hasDeleteAccess(): boolean {
    return !!this.activatedMenu?.isDelete;
  }

  get hasDownloadAccess(): boolean {
    return !!this.activatedMenu?.isDownload;
  }

  get hasAttachmentsAccess(): boolean {
    return !!this.activatedMenu?.isAttachments;
  }

  get hasProofsAccess(): boolean {
    return !!this.activatedMenu?.isProofs;
  }

  get hasConversationAccess(): boolean {
    return !!this.activatedMenu?.isConversation;
  }

  get hasBulkUploadAccess(): boolean {
    return !!this.activatedMenu?.isBulkUpload;
  }
}
